@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(380deg);
    }
    35% {
        transform: rotate(360deg);
    }
    50% {
        transform: rotate(360deg);
    }
    75% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spinning-colors {
    0% {
        color: #4e4e4e;
    }
    49% {
        color: #4e4e4e;
    }
    50% {
        color: #cd1719;
    }
    99% {
        color: #cd1719;
    }
    100% {
        color: #4e4e4e;
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@keyframes unpacking {
    0% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/package@2x.png');
    }
    50% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/cubes@2x.png');
    }
    100% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/package@2x.png');
    }
}

/**
 * TODO: Add every animation into the type [😻]
 */

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */
