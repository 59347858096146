.social {
    text-decoration: none;
    color: white !important;
    background: #f2f2f2;
    border-radius: 5px;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    position: relative;
}

.social:hover {
    filter: brightness(0.9);
}

.social.social-banner {
    display: table;
    margin-bottom: 10px;
    padding: 8px 15px;
    padding-left: 45px;
    font-size: 0.9em;
    background-size: 38px;
}

.social.social-icon {
    display: inline-block;
    width: 26px;
    height: 26px;
}

.social.facebook {
    background-color: #3b5998;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/facebook@2x.png');
}

.social.instagram {
    background-color: #f7504e;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/instagram@2x.png');
}

.social.twitter {
    background-color: #55acee;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/twitter@2x.png');
}

.social.linkedin {
    background-color: #006699;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/linkedin@2x.png');
}

.social.telegram {
    background-color: #28a0d3;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/telegram@2x.png');
}

.social.github {
    background-color: #1b1f23;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/github@2x.png');
}

.social.mail {
    background-color: #009edf;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/socials/mail@2x.png');
}

.social-group {
    margin-bottom: 30px;
    margin-top: -10px;
}

.contact {
    display: block;
    position: relative;
    padding: 0 20px;
    margin: 20px 0;
    width: 100%;
}

.contact .image {
    display: block;
    width: 50%;
    height: calc(100% + 40px);
    margin-top: -20px;
    top: 0;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contact.contact-left {
    text-align: left;
    padding-right: 50%;
}

.contact.contact-right {
    text-align: right;
    padding-left: 50%;
}

.contact.contact-left .image {
    right: 0;
}

.contact.contact-right .image {
    left: 0;
}

.contact p {
    opacity: 0.5;
    font-size: 0.9em;
    line-height: 1.3em;
    display: block;
    min-height: 100px;
}

.contact .socials {
    margin-top: -20px;
}

@media /*[🍕]*/ only screen and (max-width: 850px) {
    .contact .image {
        display: block;
        width: 100%;
        height: 200px;
        top: unset;
        position: unset;
        margin-top: 0px;
        margin-bottom: -20px;
    }
    .contact.contact-left {
        padding-right: 0;
    }

    .contact.contact-right {
        padding-left: 0;
        text-align: left;
    }
}
